<template>
	<div
		class="flex-column full-height bg-gray-light"
	>
		<div
			class="bg-title bg-base "
		>
			<button
				:title="program.name"
				@click="toBack()"
			><v-icon large class="color-white">mdi-chevron-left</v-icon><span class=" font-weight-bold size-em-15 vertical-middle">{{ program.name }}</span></button>
		</div>

		<div
			class="pa-10 flex-column full-height overflow-y-auto box-join pb-50"
		>
			<div
				class=" "
			>
				<h6>이름</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.name }}
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>연락처</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.phone }}
				</div>
			</div>

			<div
				class=" mt-10"
			>
				<h6>생년월일</h6>
				<div class="pa-10 input-underline bg-gray-light mt-10-m">
					{{ user.birth }}
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>아이디</h6>
				<input
					v-model="item.member_id"
					type="text" placeholder="아이디를 입력하세요"
					class="pa-10 input-underline"
					:disabled="isCheckId"
					:rules="[$rules.id(item, 'member_id', { min: 6, max: 25})]"
				/>
			</div>

			<div
				class="mt-10"
			>
				<h6>비밀번호</h6>
				<input
					v-model="item.member_password"
					type="password" placeholder="영문/숫자/특수문자 포함 8~20자리"
					class="pa-10 input-underline"
					maxlength="50"
				/>
				<div
					v-if="this.txtValidCheckPass != ''"

					class="mt-10 text-right color-red"
				>{{ this.txtValidCheckPass }}</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>비밀번호 확인</h6>
				<input
					v-model="item.member_password_confirm"
					type="password" placeholder="비밀번호를 한번 더 입력하세요"
					class="pa-10 input-underline"
					maxlength="50"
				/>
				<div
					v-if="item.member_password_confirm && item.member_password && (item.member_password_confirm != item.member_password)"

					class="mt-10 text-right color-red"
				>비밀번호를 확인하세요</div>
			</div>


			<div
				v-if="false"
				class="mt-10"
			>
				<h6>주소</h6>
				<div
					class="mt-10 flex-row"
				>
					<input
						v-model="item.member_post"
						type="number" placeholder="우편번호"
						class="input-box flex-2"
						readonly

						:rules="[$rules.numeric(item, 'member_post', 10)]"
					/>
					<button
						class="btn btn-blue flex-1"
						@click="daumPost('default')"
					>주소 검색</button>
				</div>
				<div
					class="mt-10"
				>
					<input
						v-model="item.member_addr1"
						type="text" placeholder="기본 주소"
						class="input-box"
						readonly
						maxlength="80"
					/>
				</div>
				<div
					class="mt-10"
				>
					<input
						v-model="item.member_addr2"
						type="text" placeholder="상세 주소를 입력하세요"
						class="input-box"
						maxlength="50"
					/>

				</div>
			</div>

			<div
				v-if="is_on_confirm"
				class="mt-10"
			>
				<h6>SMS 인증</h6>
				<div class="justify-space-between">
					<input
						v-model="item.sms_code"
						type="number" placeholder="인증코드를 입력하세요"
						class="input-underline flex-1"
						:rules="[$rules.max(item, 'sms_code', 6)]"
						:readonly="is_readonly_code"
					/>
					<button
						v-if="mode == 'confirm'"
						class="btn-inline btn-primary"
						disabled

					>인증 완료</button>
					<button
						v-else
						class="btn-inline btn-primary"
						:disabled="is_disabled_confirm"

						@click="postConfirm"
					>확인</button>
				</div>
			</div>

			<div
				v-if="false"
				class="mt-10"
			>
				<h6>이메일</h6>
				<input
					v-model="item.member_email"
					type="text" placeholder="이메일을 입력하세요"
					class="input-underline"
					maxlength="50"
				/>
				<div
					v-if="this.txtValidCheckEmail != ''"

					class="mt-10 text-right color-red"
				>{{ this.txtValidCheckEmail }}</div>
			</div>

			<div
				v-if="false"
				class="mt-10"
			>
				<h6>이용약관</h6>
				<div
					class="mt-10 input-box"
				>이용약관</div>

				<div
					class="mt-10 mb-30"
					@click="item.is_agree = !item.is_agree"
				>
					<v-icon
						v-if="item.is_agree"
						class="color-green"
					>mdi mdi-checkbox-marked</v-icon>
					<v-icon
						v-else
					>mdi mdi-checkbox-blank-outline</v-icon>

					이용약관 동의
				</div>
			</div>

			<div
				class="mt-10"
			>
				<h6>가입 코드</h6>
				<input
					v-model="item.admin_code"
					type="text"
					class=" pa-10 input-underline"
					placeholder="가입 코드를 입력하세요"
					:disabled="isGetAdminCode"
				/>
			</div>
		</div>

		<div
			class="bottom "
		>
			<button
				:disabled="isNext"
				class="btn btn-identify pa-10"
				@click="next"
			>다음</button>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"
		></DaumPost>

	</div>
</template>


<script>

import DaumPost from '@/components/Daum/DaumPost'

export default{
	name: 'AppLogin'
	,props: ['Axios', 'codes', 'rules', 'user']
	, components: { DaumPost }
	,data: function(){
		return {
			program: {
				name: '회원가입'
				, code: 'join'
				, top: false
				, title: false
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
			}
			,search: {
				COMPFRNAME: ''
			}
			,daumPostUp: false
			,txtValidCheckPass: ''
			,txtValidCheckEmail: ''
			,txtValidCheckCompany: ''
			,item: {
				member_id: ''
				, member_email: ''
				, admin_code: this.$route.params.code
				, is_agree: false
				, member_phone: ''
				, sms_code: ''
				, member_birth: ''
				, tokenVersionId: ''
			}
			, is_check_id: false
			, is_on_confirm: false
			, mode: ''
		}
	}
	,computed: {
		isNext: function(){
			const self = this
			const required = ['member_id', 'member_password', 'member_password_confirm']
			let isNext = false
			for(let i = 0; i < required.length; i++){
				if(!self.item[required[i]]){
					isNext = true
					break
				}
			}

			// 비밀번호 유효성 검사
			let isPass = this.$rules.password(self.item, 'member_password')
			if(isPass === true){
				self.txtValidCheckPass = ''
			}else{
				self.txtValidCheckPass = isPass
				isNext = true
			}

			// 비밀번호 체크
			if(self.item['member_password_confirm'] != self.item['member_password']){
				isNext = true
			}

			// 이메일 유효성 검사
			if(this.$rules.email(self.item, 'member_email')){
				self.txtValidCheckEmail = ''
			}else{
				if(self.item.member_email.length == 0){
					self.txtValidCheckEmail = ''
				}else{
					self.txtValidCheckEmail = '잘못된 형식의 이메일 주소입니다.'
				}

				// isNext = true
			}

			return isNext
		}
		,isGetAdminCode: function(){
			if(this.$route.params.code){
				return true
			}else{
				return false
			}
		}
		,isCheckId: function(){
			if(this.is_check_id){
				return true
			}else{
				return false
			}
		}
		, is_disabled_request: function(){
			let t = true
			if(this.mode == '' && this.item.member_phone){
				t = false
			}
			return t
		}
		, is_disabled_confirm: function(){
			let t = true
			if(this.item.sms_code.length == 6){
				t = false
			}
			return t
		}
		, is_readonly_phone: function(){
			let t = false
			if(this.mode != ''){
				t = true
			}
			return t
		}
		, is_readonly_code: function(){
			let t = false
			if(this.mode == 'confirm'){
				t = true
			}
			return t
		}
	}
	,methods: {
		daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		,next: async function(){

			try{

				this.item.member_name = this.user.name
				this.item.member_tell = this.user.phone
				this.item.member_birth = this.user.birth
				this.item.tokenVersionId = this.user.tokenVersionId
				let result = await this.$Axios({
					method: 'post'
					,url: '/auth/postJoin'
					,data: this.item
				})
				if(result.success){
					this.toJoinResult()
				}else{
					throw result.message
				}
			}catch(e){
				// console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,toJoinResult: function(){
			this.$router.push({ name: 'JoinResult'})
		}
		,toBack: function(){
			this.$emit('back')
		}
		, postRequest: async function(){
			try {
				this.$bus.$emit('on', true)
				this.mode = 'request'
				let result = await this.$Axios({
					method: 'post'
					,url: '/auth/postSmsAuth'
					,data: {
						member_tell: this.item.member_phone
					}
				})
				if(result.success){
					this.is_on_confirm = true
				}else{
					throw result.message
				}
			}catch(e){
				// console.log(e.message)
				this.mode = ''
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postConfirm: async function(){
			try {
				this.$bus.$emit('on', true)
				this.mode = 'request'
				let result = await this.$Axios({
					method: 'post'
					,url: '/auth/postSmsConfirm'
					,data: {
						member_tell: this.item.member_phone
						, sms_code: this.item.sms_code
					}
				})
				if(result.success){
					this.is_on_confirm = true
					this.mode = 'confirm'
					this.$bus.$emit('notify', { type: 'success', message: result.message})
				}else{
					throw result.message
				}
			}catch(e){
				// console.log(e.message)
				this.mode = 'fail'
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, resetRequest: function(){
			this.mode = ''
			this.item.sms_code = ''
			this.is_on_confirm = false
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>
.box-join > div {
	padding: 10px; background-color: white;
}
</style>
