<template>
	<div
		class="full-height flex-column "
	>
		<div class="full-height flex-column justify-center pa-10 bg-white">
			<div>
				<h5 class="color-primary">이용약관</h5>
				<div
					class="box pa-20 mt-10 min-height-150 overflow-y-auto"
				></div>
				<div class="mt-10"><label> <input v-model="terms" type="checkbox" /> 이용약관에 동의 합니다</label></div>
			</div>
			<div class="mt-30">
				<h5 class="color-primary">개인정보처리방침</h5>
				<div
					class="box pa-20 mt-10 min-height-150 overflow-y-auto"
				></div>
				<div class="mt-10"><label> <input v-model="personal" type="checkbox" /> 이용약관에 동의 합니다</label></div>
			</div>
		</div>

		<div class="mt-auto text-center">
			<button
				class="btn btn-primary pa-10"
				@click="onConfirm"
				:disabled="is_disabled"
			>본인 인증</button>
		</div>

		<div
			v-if="is_on_confirm"
			class="position-fixed-full"
		>

			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>

		<AuthConfirm
			v-if="is_on_auth"
			confirm_type="join"

			@click="next"
			@cancel="is_on_auth = false"
			@fail="fail"
		></AuthConfirm>
	</div>
</template>
<script>

import AuthConfirm from "@/view/Layout/AuthConfirm";
export default{
	name: 'AuthAgree'
	, components: {AuthConfirm}
	, props: ['codes']
	, data: function(){
		return {
			program: {
				name: '본인 인증 및 약관 동의'
				, code: 'join'
				, title: true
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
				, top_line: true
			}
			, terms: false
			, personal: false
			, is_on_confirm: false
			, nice_url: ''
			, type: 'pass'
			, is_on_auth: false
		}
	}
	,computed: {
		is_disabled: function(){
			let t = true
			if(this.terms && this.personal){
				t = false
			}
			return t
		}
	}
	,methods: {
		onConfirm: async function(){
			this.is_on_auth = true
		}
		, cancel: function(){
			window.location.reload()
		}
		, next: function(item){
			this.$emit('click', item)
		}
		, fail: function(message){
			this.$emit('fail', message)
		}
	}
	, destroyed() {

	}

	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>

</style>