<template>
	<AuthAgree
		v-if="step == 1"

		@click="next"
		@fail="fail"
	></AuthAgree>

	<JoinForm
		v-else-if="step == 2"
		:user="item"
		@click="setItem"
		@back="step = 1"
	></JoinForm>

	<JoinResult
		v-else-if="step == 4"
		:user="item"
	></JoinResult>

	<JoinFalse
		v-else-if="step == 3"
		:message="message"
	></JoinFalse>
</template>


<script>

import AuthAgree from "./Agree";
import JoinForm from "./JoinForm";
import JoinFalse from "@/view/Auth/JoinFalse";
import JoinResult from "@/view/Auth/Result";

export default{
	name: 'Join'
	,props: ['codes']
	, components: {JoinResult, JoinFalse, JoinForm, AuthAgree, }
	,data: function(){
		return {
			program: {
				name: '회원가입'
				, code: 'join'
				, title: true
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
				, top_line: true
			}
			, step: 1
			, item: {
				name: ''
				, phone: ''
				, birth: ''
				, tokenVersionId: ''
			}
			, message: ''
		}
	}
	,computed: {

	}
	,methods: {
		next: function(data){
			this.item.name = data.name
			this.item.phone = data.phone
			this.item.birth = data.birth
			this.item.tokenVersionId = data.tokenVersionId
			this.step = 2
			this.$bus.$emit('on', false)
		}
		, fail: function(message){
			this.step = 3
			this.message = message
		}
		, setItem: function(item){
			this.item = item
			this.step = 4
		}
	}
	,created: function(){
		this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>

</style>