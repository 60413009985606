<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div
				v-if="is_on_kakako"
				class="bg-white "
			>
				<div class="pa-10 justify-space-between bg-identify">
					<h6>카카오 인증 요청</h6>
					<button
						@click="offKakao"
					><v-icon class="color-eee">mdi-close-circle</v-icon></button>
				</div>
				<div
					v-if="item_kakao.tokenVersionId"
					class="mt-30 gap-20 pa-20"
				>
					카카오 본인 인증이 요청되었습니다. <br/><br/>
					휴대폰에서 확인해 주세요 <br/><br/>
					인증 완료 후 아래 인증 확인 버튼을 눌러주세요 <br/>
				</div>
				<div
					v-else
					class="mt-30 gap-20 pa-20"
				>
					<div>
						<label><h6>이름</h6>
							<input v-model="item_kakao.name" placeholder="이름을 입력하세요"  class="input-box" maxlength="20"/>
						</label>
					</div>
					<div class="mt-10">
						<label><h6>휴대폰 번호</h6>
						<input v-model="item_kakao.phone" placeholder="-없이 숫자만 입력하세요" class="input-box" type="number" :rules="$rules.max(item_kakao, 'phone', 12)"/>

						</label>
					</div>
					<div class="mt-10">
						<label><h6>생년월일</h6>
						<input v-model="item_kakao.birth" placeholder="ex) 20020130"  class="input-box" type="number"  :rules="$rules.max(item_kakao, 'birth', 8)"/>
						</label>
					</div>
				</div>
				<div class="mt-30">
					<button
						v-if="item_kakao.tokenVersionId"
						class="btn bg-kakao"
						@click="postKakaoConfrim"
					>본인 인증 확인</button>
					<button
						v-else
						class="btn bg-kakao"
						@click="postKakao"
					>본인 인증 요청</button>
				</div>
			</div>
			<div
				v-else
				class="bg-white "
			>
				<div class="pa-10 justify-space-between bg-identify">
					<h6>본인 인증</h6>
					<button
						@click="$emit('cancel')"
					><v-icon class="color-eee">mdi-close-circle</v-icon></button>
				</div>
				<div class="mt-30 justify-space-between gap-20 pa-20">
					<div
						v-if="false"
						class="pa-10 box-shadow flex-1"
						:class="{ on : type == 'naver'}"
						@click="type = 'naver'"
					>네이버 인증</div>
					<button
						class="pa-10  flex-1 "
						:class="{ 'opacity05' : type != 'pass'}"
						@click="type = 'pass'"
					><img :src="require('@/assets/images/pass.jpg')" class="width-100"></button>
					<button
						class="pa-10  flex-1 "
						:class="{ 'opacity05' : type != 'kakao'}"
						@click="type = 'kakao'"
					><img :src="require('@/assets/images/kakao.png')" class="width-100"></button>
				</div>
				<div class="mt-30">
					<button
						class="btn btn-primary"
						@click="postConfirm"
					>본인 인증</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
	import PopupLayer from "@/view/Layout/PopupLayer";
	export default {
		name: 'AuthConfirm'
		, components: {PopupLayer}
		, props: ['confirm_type']
		, data: function(){
			return {
				type: 'pass'
				, nice_url: ''
				, is_on_kakako: false
				, item_kakao: {
					phone: ''
					, name: ''
					, birth: ''
				}
			}
		}
		, methods: {

			postConfirm: function(){
				if(this.type == 'naver'){
					this.postNaver()
				}else if(this.type == 'kakao'){
					this.toKakao()
				}else if(this.type == 'pass'){
					this.postPass()
				}
			}
			, postPass: async function(){
				window.open(this.nice_url, 'nice', 'width=320px')
			}
			, postNaver: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'naver/postNaver'
						,data: {
						}
					})

					if(result.success){
						this.items_file = result.data.file_data
						this.items_account = result.data.account_data
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postKakaoConfrim: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: 'kakao/postKakaoConfirm'
						,data: this.item_kakao
					})

					if(result.success){
						this.$emit('click', this.item_kakao)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postKakao: async function(){
				try{
					this.$bus.$emit('on', true)
					this.$set(this.item_kakao, 'type', this.confirm_type)
					this.$set(this.item_kakao, 'auth_type', this.type)

					const result = await this.$request.init({
						method: 'post'
						,url: 'kakao/postKakao'
						,data: this.item_kakao
					})

					if(result.success){
						this.$set(this.item_kakao, 'tokenVersionId', result.data)
					}else{
						throw result.message
					}
				}catch (e) {
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, niceResult: function(e){
console.log('niceResult', e.origin, e.data)
				try {
					if(e.origin.indexOf('paytree.kr') > -1){

						let data = e?.data
						if(data && typeof data == 'string'){
							data = JSON.parse(data)
							console.log(data.type, this.confirm_type)
							if(data.type == this.confirm_type){
								if(data.result){
									data.data.auth_type = this.type
									this.$emit('click', data.data)
								}else{
									throw data.message
								}
							}
						}
					}
				}catch (e) {
					this.$emit('fail', e)
				}
			}
			, toKakao: function(){
				this.is_on_kakako = true
			}
			, offKakao: function(){
				this.is_on_kakako = false
				this.item_kakao = {}
			}
		}
		, destroyed() {
			window.removeEventListener('message', this.niceResult)
		}
		, created() {

			let domains = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER
			let location = window.location.hostname

			let baseUrl = ''

			domains = domains.split('|')
			for(let i = 0; i < domains.length; i++) {
				if (location == domains[i]) {
					baseUrl = server
					break
				} else {
					baseUrl = dev
				}
			}

			this.nice_url = baseUrl + '/nice/request?type=' + this.confirm_type + (this.confirm_type != 'join' ? '&P=' + sessionStorage.getItem(process.env.VUE_APP_NAME + 'T3') : '' )

			window.removeEventListener('message', this.niceResult)
			setTimeout( () => {
				window.addEventListener('message', this.niceResult)
			}, 100)
		}
	}
</script>